<!-- @format -->

<template>
  <div>
    <MobileHeader />
    <div class="mobile-artists">
      <div
        v-for="(artist, index) in $texte.artists"
        :key="artist.text"
        class="mobile-artists__wrapper"
        :class="$mq"
        :id="`mob-a${index}`"
      >
        <div v-if="num === index" class="mobile-artists__inner">
          <div class="mobile-artists__inner-image">
            <img
              :src="require(`@/assets/images/kuenstler/${artist.image}`)"
              alt=""
            />
          </div>
          <div class="mobile-artists__nav">
            <h1>Die Künstlerinnen</h1>
            <nav>
              <div :style="num === 0 && active" @click="num = 0">
                MARINA ABRAMOVIĆ
              </div>
              <div :style="num === 1 && active" @click="num = 1">
                SIGALIT LANDAU
              </div>
              <div :style="num === 2 && active" @click="num = 2">
                SHIRIN NESHAT
              </div>
              <div :style="num === 3 && active" @click="num = 3">
                KATHARINA SIEVERDING
              </div>
            </nav>
          </div>
          <div class="mobile-artists__arrow"></div>
          <div class="mobile-artists__text">
            <div class="mobile-artists__name" v-html="artist.name"></div>
            <div v-html="artist.more"></div>
          </div>
        </div>
      </div>
      <router-link to="/mobile" class="mobile-artists__btn-close">
        <Arrow /> Zurück zur Webseite
      </router-link>
    </div>
    <BaseFooter :kinostart="kinostart" :billing="true" :youtubenote="false" />
  </div>
</template>

<script>
  import Arrow from 'vue-material-design-icons/ArrowLeftBoldCircle'
  import moment from 'moment'

  export default {
    components: {
      Arrow,
    },
    data() {
      return {
        num: 0,
      }
    },
    computed: {
      kinostart() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
      active() {
        return `fontWeight: 900 !important;
                    color: black;`
      },
    },
  }
</script>

<style lang="scss">
  .mobile-artists {
    $self: &;
    padding-top: 51px;
    &__wrapper {
      #{$self}__inner {
        #{$self}__inner-image {
          width: 100%;
          img {
            width: 100%;
          }
        }
        #{$self}__nav {
          background-color: #fff;
          padding: 50px 5%;
          color: $h1;
          cursor: pointer;
          h1 {
            color: $h1;
            font-weight: 700;
            margin-bottom: 20px;
          }
        }
        #{$self}__arrow {
          margin-left: 5%;
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;

          border-top: 20px solid #fff;
        }
        #{$self}__text {
          color: white;
          padding: 50px 5%;
          #{$self}__name {
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 20px;
          }
        }
      }
    }
    &__btn-close {
      padding: 0 5%;
      color: white;
      font-weight: 700;
    }
  }
</style>
